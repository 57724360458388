<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/language' }">
        <app-i18n code="entities.language.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.language.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.language.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-language-view-toolbar
        v-if="record"
      ></app-language-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.code.label"
          :value="presenter(record, 'code')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageViewToolbar from '@/modules/language/components/language-view-toolbar.vue';
import { LanguageModel } from '@/modules/language/language-model';

const { fields } = LanguageModel;

export default {
  name: 'app-language-view-page',

  props: ['id'],

  components: {
    [LanguageViewToolbar.name]: LanguageViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'language/view/record',
      loading: 'language/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'language/view/doFind',
    }),

    presenter(record, fieldName) {
      return LanguageModel.presenter(record, fieldName);
    },
  },
};
</script>
